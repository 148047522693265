import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import socialDistancing from "../images/undraw_social_distancing.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center pb-8">
        <img
          alt="Not Found"
          className="block mx-auto w-1/2 mt-4"
          src={socialDistancing}
        />
        <h2 className="text-2xl font-bold inline-block my-8 p-3">Not Found</h2>
        <p>This page does not exist.</p>
        <Link to="/">
          <button className="bg-blue-700 hover:bg-blue-600  text-white text-2xl  px-3 py-1 mt-3 my-auto rounded transition duration-100 items-center">
            Return Home
          </button>
        </Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
